<template>
  <div class="content_body ReportEntityProductAccountStatistics" style="padding:15px" v-loading="loading">
    <!-- 筛选 -->
    <div class="nav_header" style="padding:0 0 15px 0">
      <el-form :inline="true" size="small" :model="entityProductData" @submit.native.prevent>
        <el-form-item label="产品">
          <el-input v-model="entityProductData.Name" clearable @keyup.enter.native="handleEntityProductSearch"
            @clear="handleEntityProductSearch" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="品牌">
          <el-select v-model="entityProductData.BrandID" clearable filterable placeholder="请选择产品品牌"
            :default-first-option="true" @change="handleEntityProductSearch" @clear="handleEntityProductSearch">
            <el-option v-for="item in productBrandList" :key="item.ID" :label="item.Name" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属门店">
          <el-select v-model="entityProductData.EntityID" clearable filterable placeholder="请选择门店"
            :default-first-option="true" @change="handleEntityProductSearch">
            <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品分类">
          <el-cascader @change="handleEntityProductSearch" @clear="handleEntityProductSearch" :options="productTypeList"
            :show-all-levels="true" clearable filterable :props="cascaderProps" v-model="entityProductData.CategoryID"
            placeholder="请选择产品分类"></el-cascader>
        </el-form-item>
        <el-form-item label="时间筛选">
          <el-date-picker v-model="entityProductData.QueryDate" unlink-panels type="daterange" clearable filterable
            range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"
            @change="handleEntityProductSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="handleEntityProductSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-if="EntityProductExport" type="primary" size="small" :loading="downloadLoading"
            @click="downloadEntityProductExcel">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <el-table size="small" show-summary :summary-method="getEntityProductListSummaries" :data="result.list"
      v-loading="EntityProductLoading">
      <el-table-column prop="EntityName" label="门店"></el-table-column>
      <el-table-column prop="ParentCategoryName" label="一级分类"></el-table-column>
      <el-table-column prop="CategoryName" label="二级分类"></el-table-column>
      <el-table-column prop="ProductName" label="产品名/规格"></el-table-column>
      <el-table-column prop="BrandName" label="产品品牌"></el-table-column>
      <el-table-column prop="Price" align="right" label="单价">
        <template slot-scope="scope">
          {{scope.row.Price | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="SalePrice" align="right" label="销售均价">
        <template slot-scope="scope">
          {{scope.row.SalePrice | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="SaleCount" align="right" label="购买人数"></el-table-column>
      <el-table-column prop="SaleQuantity" align="right" label="购买数量"></el-table-column>
      <el-table-column prop="PayPerformance" align="right" label="收现金额">
        <template slot-scope="scope">
          {{scope.row.PayPerformance | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="SavingCardPerformance" align="right" label="卡抵扣金额">
        <template slot-scope="scope">
          {{scope.row.SavingCardPerformance | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="SavingCardLargessPerformance" align="right" label="赠卡抵扣金额">
        <template slot-scope="scope">
          {{scope.row.SavingCardLargessPerformance | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="LargessQuantity" align="right" label="赠送数量"></el-table-column>
      <el-table-column prop="LargessAmount" align="right" label="赠送价值">
        <template slot-scope="scope">
          {{scope.row.LargessAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="Balance" align="right" label="顾客寄存数量"></el-table-column>
      <el-table-column prop="OutBound" align="right" label="本期出库"></el-table-column>
      <el-table-column prop="InBound" align="right" label="本期入库"></el-table-column>
      <el-table-column prop="BalanceStock" align="right" label="当前仓库剩余数量">
        <template align="right" slot-scope="scope">
          <div v-if="scope.row.BalanceStock<0" class="color_red">{{scope.row.BalanceStock }}</div>
          <div v-else-if="scope.row.BalanceStock>=0">{{scope.row.BalanceStock }}</div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pad_15 text_right">
      <el-pagination background v-if="EntityProductPaginations.total > 0"
        @current-change="handleEntityProductPageChange" :current-page.sync="EntityProductPaginations.page"
        :page-size="EntityProductPaginations.page_size" :layout="EntityProductPaginations.layout"
        :total="EntityProductPaginations.total"></el-pagination>
    </div>
  </div>
</template>

<script>
import API from "@/api/Report/Entity/productAccountStatistics";
import APIC from "@/api/PSI/Product/productCategory";
import productBrandAPI from "@/api/PSI/Product/productBrand";
import EntityAPI from "@/api/Report/Common/entity";
import permission from "@/components/js/permission.js";
import dateTime from "@/components/js/date";
export default {
  name: "ReportEntityProductAccountStatistics",

  components: {},

  directives: {},

  data() {
    return {
      loading: false,
      storeEntityList: [], //权限下所属门店
      productBrandList: [], //品牌列表
      productTypeList: [], //产品分类
      cascaderProps: {
        value: "ID",
        label: "Name",
        children: "Child",
        emitPath: false,
        expandTrigger: "hover",
      }, // 级联选择器配置项
      // 搜索条件
      entityProductData: {
        EntityID: null,
        Name: "",
        QueryDate: [],
        CategoryID: null,
        BrandID: null,
      },
      // 搜索结果
      result: {
        totalForm: {},
        list: [],
      },
      EntityProductExport: false, //导出权限
      downloadLoading: false,
      EntityProductLoading: false,
      EntityProductPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
    };
  },
  created() {
    this.entityProductData.QueryDate = [
      dateTime.formatDate.format(new Date(), "YYYY-MM-DD"),
      dateTime.formatDate.format(new Date(), "YYYY-MM-DD"),
    ];
  },
  mounted() {
    const that = this;
    //   导出权限
    console.log(that.$route.meta.Permission);
    that.EntityProductExport = permission.permission(
      that.$route.meta.Permission,
      "Report-Entity-ProductAccountStatistics-Export"
    );

    //获得当前用户下的权限门店
    that.getstoreEntityList();
    // 获取产品品牌列表
    that.getProductBrandList();
    // 获取产品分类
    that.getProductType();
    // 搜索
    that.handleEntityProductSearch();
  },

  methods: {
    //获得当前用户下的权限门店
    getstoreEntityList() {
      var that = this;
      EntityAPI.getStoreEntityList()
        .then((res) => {
          if (res.StateCode == 200) {
            that.storeEntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取产品品牌列表
    getProductBrandList: function () {
      let that = this;
      var params = {
        Name: "",
        Active: true,
      };
      productBrandAPI
        .getProductBrandList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productBrandList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    // 获取产品分类
    getProductType: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: "",
        Active: true,
      };
      APIC.getValidProductCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productTypeList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 搜索
    handleEntityProductSearch() {
      var that = this;
      that.EntityProductPaginations.page = 1;
      that.getEntityProductList();
    },
    //   获取门店产品销售寄存列表
    getEntityProductList() {
      const that = this;
      const param = that.entityProductData;

      if (param.QueryDate != null) {
        that.EntityProductLoading = true;
        const params = {
          EntityID: param.EntityID,
          Name: param.Name,
          ParentCategoryID: param.ParentCategoryID,
          CategoryID: param.CategoryID,
          BrandID: param.BrandID,
          StartTime: param.QueryDate[0],
          EndTime: param.QueryDate[1],
          PageNum: that.EntityProductPaginations.page,
        };
        API.EntityProductList(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.result.totalForm =
                res.Data.entityProductStockDetailSumStatementForm;
              that.result.list = res.Data.detail.List;
              that.EntityProductPaginations.total = res.Data.detail.Total;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.EntityProductLoading = false;
          });
      }
    },
    // 导出
    downloadEntityProductExcel() {
      var that = this;
      const param = that.entityProductData;
      if (param.QueryDate != null) {
        const params = {
          EntityID: param.EntityID,
          Name: param.Name,
          ParentCategoryID: param.ParentCategoryID,
          CategoryID: param.CategoryID,
          BrandID: param.BrandID,
          StartTime: param.QueryDate[0],
          EndTime: param.QueryDate[1],
        };
        that.downloadLoading = true;
        API.EntityProductExcel(params)
          .then((res) => {
            this.$message.success({
              message: "正在导出",
              duration: "4000",
            });
            const link = document.createElement("a");
            let blob = new Blob([res], { type: "application/octet-stream" });
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = "门店产品销售寄存分析.xlsx"; //下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .finally(function () {
            that.downloadLoading = false;
          });
      }
    },
    // 合计
    getEntityProductListSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "SaleCount":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.SaleCount}
              </span>
            );
            break;
          case "SaleQuantity":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.SaleQuantity}
              </span>
            );
            break;
          case "PayPerformance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.PayPerformance)}
              </span>
            );
            break;
          case "SavingCardPerformance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.SavingCardPerformance)}
              </span>
            );
            break;
          case "SavingCardLargessPerformance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.result.totalForm.SavingCardLargessPerformance
                )}
              </span>
            );
            break;
          case "LargessQuantity":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.LargessQuantity}
              </span>
            );
            break;
          case "LargessAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.LargessAmount)}
              </span>
            );
            break;
          case "Balance":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.Balance}
              </span>
            );
            break;
          case "OutBound":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.OutBound}
              </span>
            );
            break;
          case "InBound":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.InBound}
              </span>
            );
            break;
          case "BalanceStock":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.BalanceStock}
              </span>
            );
            break;
          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },
    // 分页
    handleEntityProductPageChange() {
      this.getEntityProductList();
    },
  },
};
</script>

<style lang="scss">
.ReportEntityProductAccountStatistics {
}
</style>
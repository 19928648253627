/**
 * 门店产品
 */
import * as API from '@/api/index'

export default {
    // 门店销售消耗列表
    EntityProductList: params => {
        return API.POST('api/entityProductStockDetailStatement/list', params)
    },
    // 门店销售消耗导出
    EntityProductExcel: params => {
        return API.exportExcel('api/entityProductStockDetailStatement/excel', params)
    },
    //  一级分类
    ProductCategoryTop: params => {
        return API.POST('api/productCategory/top', params)
    },

    //  二级分类
    ProductCategoryChild: params => {
        return API.POST('api/productCategory/child', params)
    },
}
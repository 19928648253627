/**
 * Created by jerry on 2020/02/14.
 * 产品类别api
 */
import * as API from '@/api/index'

export default {
    // 获取产品分类列表 一级
    getProductCategoryTop: params => {
        return API.POST('api/productCategory/top', params)
    },
    // 获取产品分类列表
    getProductCategory: params => {
        return API.POST('api/productCategory/all', params)
    },
    // 创建产品类别
    createProductCategory: params => {
        return API.POST('api/productCategory/create', params)
    },
    // 编辑更新产品类别
    updateProductCategory: params => {
        return API.POST('api/productCategory/update', params)
    },
    // 调整产品类别顺序
    moveProductCategory: params => {
        return API.POST('api/productCategory/move',params)
    },
    // 获取产品分类列表(可以，去除没有二级的类别)
    getValidProductCategory() {
        return API.POST('api/productCategory/valid')
    },
}

/**
 * Created by preference on 2021/03/31
 *  zmx 
 */

 import * as API from '@/api/index'
 export default {
   /** 9.1.产品品牌列表（不分页）  */
   getProductBrandList: params => {
     return API.POST('api/productBrand/all', params)
   },
   /** .9.2.创建产品品牌  */
   createProductBrand: params => {
     return API.POST('api/productBrand/create', params)
   },
   /**9.3.更新产品品牌）  */
   updateProductBrand: params => {
     return API.POST('api/productBrand/update', params)
   },
   /**9.4.调整产品品牌顺序  */
   moveProductBrand: params => {
     return API.POST('api/productBrand/move', params)
   },
 }